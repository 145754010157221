import {define, html, LitElement} from '../common/commons';
import {
  renderContentHeader,
  renderLayout,
  renderStack,
} from '../common/templates';


import '@domg-wc/elements/src/lib/grid';
import '@domg-wc/elements/src/lib/title';
import '@domg-wc/elements/src/lib/link';
import '@domg-wc/elements/src/lib/link-list';
import '@domg-wc/elements/src/lib/doormat';
import '@domg-wc/components/src/lib/alert';

class GrupsHome extends LitElement {
  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${renderContentHeader('/')}
      <section is="vl-region">
        ${renderLayout([this.__renderContent()])}
      </section>
    `;
  }

  __renderContent() {
    return renderStack([
      {size: 8, template: this.__renderIntroduction()},
      {size: 4, template: this.__renderLeesOok()},
      {size: 12, template: this.__renderAlert()},
      {size: 6, template: this.__renderInspraakDoormat()},
      {size: 6, template: this.__renderRupsZoekenDoormat()},
    ]);
  }

  __renderIntroduction() {
    return html`
      <h1 is="vl-h1">Gewestelijke ruimtelijke uitvoeringsplannen</h1>
      <vl-typography>
        <p>
          Een <a is="vl-link" href="/grup-procedure">ruimtelijk
          uitvoeringsplan</a>
          legt voor het plangebied vast:
        <ul>
          <li>Welke activiteiten er mogen plaatsvinden</li>
          <li>Waar er mag gebouwd worden</li>
          <li>Aan welke stedenbouwkundige voorschriften constructies moeten
            voldoen
          </li>
        </ul>
        </p>
        <p>
          Een ruimtelijk uitvoeringsplan geeft uitvoering aan een ruimtelijk
          structuurplan.
          Het heeft een verordenende, dus verplichtende waarde voor iedereen.
        </p>
      </vl-typography>
    `;
  }

  __renderLeesOok() {
    return html`
      <h5 is="vl-h5" data-vl-alt>Lees ook</h5>
      <ul is="vl-link-list" id="link-list">
        <li is="vl-link-list-item">
          <a is="vl-link"
             href="https://dsi.omgeving.vlaanderen.be/fiche-overzicht"
          >Alle plannen met stedenbouwkundige informatie in Vlaanderen</a>
        </li>
        <li is="vl-link-list-item">
          <a is="vl-link" href="https://inspraak.omgeving.vlaanderen.be"
          >Inspraak binnen beleidsdomein omgeving</a>
        </li>
        <li is="vl-link-list-item">
          <a is="vl-link"
             href="https://geoplannen.omgeving.vlaanderen.be/roviewer/?t=7&m=1&category=2"
          >Geoportaal ruimtelijke plannen en verordeningen</a>
        </li>
      </ul>
      <div class="grups-contact">
        <h5 is="vl-h5" data-vl-alt>Contacteer ons</h5>
        <p>Afdeling Gebiedsontwikkeling, Omgevingsplanning en -projecten</p>
        <p>
          <a is="vl-link" href="mailto:omgevingsplanning@vlaanderen.be"
             target="_top">
            <span
              is="vl-icon"
              data-vl-icon="envelope"
              data-vl-before>
            </span><span>omgevingsplanning@vlaanderen.be</span>
          </a>
        </p>
        <p>
          <span
            is="vl-icon"
            data-vl-icon="phone"
            data-vl-before> 02 553 38 00</span>
        </p>
      </div>
    `;
  }

  __renderAlert() {
    return html`
      <vl-alert icon="info-circle" data-vl-title="Ter info"
                data-vl-type="success">
        Deze pagina bevat enkel ruimtelijke uitvoeringsplannen opgemaakt door de
        Vlaamse Overheid (GRUP). Ook provincies en gemeentes maken
        ruimtelijke uitvoeringsplannen. Bekijk deze plannen <a
        href="https://dsi.omgeving.vlaanderen.be/fiche-overzicht">hier</a>
        of raadpleeg de gemeente of de provincie voor een overzicht van de
        plannen op hun grondgebied.
      </vl-alert>
    `;
  }

  __renderInspraakDoormat() {
    return html`
      <a id="doormat-overzicht" is="vl-doormat" href="/overzicht">
        <h2 is="vl-doormat-title">Nu inspreken over GRUPS</h2>
      </a>
    `;
  }

  __renderRupsZoekenDoormat() {
    return html`
      <a id="doormat-zoeken" is="vl-doormat" href="/zoeken">
        <h2 is="vl-doormat-title">GRUPS zoeken</h2>
      </a>
    `;
  }
}

define('grups-home', GrupsHome);
