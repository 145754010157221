import {define, html, LitElement} from '../common/commons';
import {
  renderFunctionalHeader,
  renderLayout,
  renderRow,
} from '../common/templates';

import '@domg-wc/elements/src/lib/grid';
import '@domg-wc/elements/src/lib/title';
import '@domg-wc/components/src/lib/typography';

class GrupsInspraakVerwerking extends LitElement {
  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${renderFunctionalHeader('Gewestelijke ruimtelijke uitvoeringsplannen',
        'Wat gebeurt er met mijn inspraakreactie', '/overzicht')}
      <section is="vl-region">
        ${renderLayout([this.__renderContent()])}
      </section>`;
  }

  __renderContent() {
    return renderRow([
      {size: 12, template: this.__renderTitle()},
      {size: 12, template: this.__renderInhoud()},
    ]);
  }

  __renderTitle() {
    return html`
      <h1 is="vl-h1">Wat gebeurt er met mijn inspraakreactie</h1>
    `;
  }

  __renderInhoud() {
    return html`
      <vl-typography>
        <h2 is="vl-h2">Inspraakreactie en bezwaar</h2>
        <p>
          De procedure voor de opmaak van een ruimtelijk uitvoeringsplan is
          wettelijk geregeld in de Vlaamse Codex Ruimtelijke Ordening (VCRO).
          De VCRO voorziet vijf fases of stappen:
        <ol>
          <li>startnota</li>
          <li>scoping</li>
          <li>voorontwerp-RUP</li>
          <li>ontwerp-RUP</li>
          <li>definitief RUP</li>
        </ol>
        </p>
        <p>
          In alle fases van de procedure kan inspraak georganiseerd worden. In
          twee van die stappen is een formele inspraakperiode wettelijk verplicht:
        <ul>
          <li>
            Fase startnota. De Vlaamse Regering keurt een startnota goed en
            legt de startnota voor in een <b>raadpleging van de bevolking</b>.
            Op dat ogenblik kan iedereen een inspraakreactie indienen. Het gaat
            hier om inspraak in een zeer vroege fase van het proces waarbij
            de planopties nog niet vastliggen en eerst nog onderzoek nodig is.
          </li>
          <li>
            Fase ontwerp. Na de voorlopige vaststelling van een ruimtelijk
            uitvoeringsplan wordt een <b>openbaar onderzoek</b> georganiseerd.
            Tijdens dit openbaar onderzoek kan iedereen een <b>bezwaar</b>
            indienen over het ontwerp-RUP. Hier gaat het om inspraak over een
            RUP dat alle informatie bevat zodat er inspraak mogelijk is over
            het geheel van het plan vooraleer het finaal wordt goedgekeurd.
          </li>
        </ul>
        </p>
        <h2 is="vl-h2">Verwerking van inspraakreacties over een startnota</h2>
        <p>
          Inspraakreacties kunnen op verschillende manieren worden ingediend:
          digitaal via de website, op papier, via de gemeente, bij het
          Departement Omgeving. Alle reacties worden bijeengebracht en op
          dezelfde manier verwerkt. Waar of hoe ze zijn ingediend maakt dus
          geen verschil.
        </p>
        <p>
          De verwerking van de inspraakreacties gebeurt door het planteam. Het
          planteam onderzoekt en beoordeelt alle inspraakreacties. Daarbij
          wordt nagegaan of de startnota fouten of tekorten omvat. Anders
          gezegd opmerkingen waardoor de startnota en het later op te maken
          plan beter kan worden gemaakt. Op basis van deze inspraakreacties
          wordt de scopingnota opgemaakt. Daarnaast wordt nagegaan of in de
          inspraak de doelstellingen van het plan worden betwist. Dat kan
          leiden tot een aanpassing van de doelstellingen. Als het planteam
          oordeelt dat de opmerking onterecht is dan wordt aangegeven waarom.
          Die keuze wordt gemotiveerd. Er kunnen ook opmerkingen zijn over het
          onderzoek en het milieu-onderzoek, bijvoorbeeld over de voorgestelde
          onderzoeksmethode of de te onderzoek alternatieven. Ook die
          beoordeelt het planteam en ze kunnen leiden tot een aanpassing van
          het onderzoek of ze kunnen gemotiveerd worden weerlegd.
        </p>
        <p>
          Het planteam bundelt alle inspraakreacties en groepeert gelijkaardige
          opmerkingen. De beoordeling gebeurt ook gegroepeerd en niet op
          individuele basis per reactie.
        </p>
        <p>
          Over de raadpleging van de bevolking wordt een verslag gemaakt. In
          dat verslag is opgenomen hoeveel reacties er werden ingediend, wat
          het onderwerp is van de reacties en welk gevolg er aan de reacties
          wordt gegeven. Dit verslag wordt gepubliceerd op de website van het
          Departement Omgeving. Het is dus niet zo dat je een bericht krijgt
          met de verwerking van je inspraakreactie.
        </p>
      </vl-typography>
    `;
  }
}

define('grups-inspraak-verwerking', GrupsInspraakVerwerking);
