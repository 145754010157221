import {define, html, LitElement} from './common/commons';

import '@domg-wc/sections/src/lib/cookie-statement';
import {renderFunctionalHeader} from './common/templates';

class GrupsCookie extends LitElement {
  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <div slot="main">
        <vl-cookie-statement>
          ${renderFunctionalHeader('Departement Omgeving', 'Cookieverklaring', '/')}
        </vl-cookie-statement>
      </div>
    `;
  }
}

define('grups-cookie', GrupsCookie);
