import {html} from 'lit-html';

import '../components/grups-header';
import '../components/grups-footer';

import '@domg-wc/sections/src/lib/header';
import '@domg-wc/sections/src/lib/footer';
import '@domg-wc/components/src/lib/content-header';
import '@domg-wc/components/src/lib/functional-header';
import '@domg-wc/elements/src/lib/grid';


export const renderHeader = (template) => {
  if (template.enabled) {
    return html`
      <vl-header
        slot="header"
        ?data-vl-development="${template.development}"
        data-vl-identifier="${template.headerId}"></vl-header>`;
  }
  return html`
    <grups-header slot="header"></grups-header>`;
};

export const renderFooter = (template) => {
  if (template.enabled) {
    return html`
      <vl-footer
        slot="footer"
        ?data-vl-development="${template.development}"
        data-vl-identifier="${template.footerId}"></vl-footer>`;
  }
  return html`
    <grups-footer slot="footer"></grups-footer>`;
};


export const renderContentHeader = (titleLink) => {
  return html`
    <vl-content-header>
      <img
        is="vl-image" slot="image" style="background: #FC5104;"
        srcset="/images/banner_mozaiek.jpg 320w, /images/banner_mozaiek.jpg 1024w, /images/banner_mozaiek.jpg 1600w"
        alt=""/>
      <a slot="context-link" href="https://omgeving.vlaanderen.be/">Omgeving</a>
      <a slot="title-link" href="${titleLink}">Gewestelijke ruimtelijke uitvoeringsplannen</a>
    </vl-content-header>
  `;
};

export const renderFunctionalHeader = (title, subTitle, link) => {
  return html`
    <vl-functional-header
      slot="header"
      data-vl-title="${title}"
      data-vl-sub-title="${subTitle}"
      data-vl-link="/"
      data-vl-back-link="${link}"
      data-vl-back="Start">
    </vl-functional-header>
  `;
};

export const renderLayout = (templates) => {
  return html`
    <div is="vl-layout">${templates}</div>`;
};

const hasTemplate = (c) => c.template;

export const renderRow = (columns = []) => {
  const filteredColumns = columns.filter(hasTemplate);
  if (filteredColumns.length === 0) {
    return html``;
  }
  return html`
    <div is="vl-grid">
      ${filteredColumns.map((c) => renderColumn(c))}
    </div>`;
};

export const renderStack = (columns = []) => {
  const filteredColumns = columns.filter(hasTemplate);
  if (filteredColumns.length === 0) {
    return html``;
  }
  return html`
    <div is="vl-grid" data-vl-is-stacked>
      ${filteredColumns.map((c) => renderColumn(c))}
    </div>`;
};

export const renderColumn = (
  {
    push = 0,
    // watch devices
    extraSmallSize = 12,
    extraSmallMaxSize = 12,

    // phone devices
    smallSize = extraSmallSize,
    smallMaxSize = extraSmallMaxSize,

    // tablet devices
    mediumSize = smallSize,
    mediumMaxSize = smallMaxSize,

    // desktop devices
    size = mediumSize,
    maxSize = mediumMaxSize,

    // the template we want to render
    template = html``,
  } = {}) => {
  return html`
    <div is="vl-column"
         data-vl-push="${push}"

         data-vl-size="${size}"
         data-vl-max-size="${maxSize}"

         data-vl-medium-size="${mediumSize}"
         data-vl-medium-max-size="${mediumMaxSize}"

         data-vl-small-size="${smallSize}"
         data-vl-small-max-size="${smallMaxSize}"

         data-vl-extra-small-size="${extraSmallSize}"
         data-vl-extra-small-max-size="${extraSmallMaxSize}">

      ${template}
    </div>`;
};

export const renderFormGrid = (columns = []) => {
  const filteredColumns = columns.filter(hasTemplate);
  if (filteredColumns.length === 0) {
    return html``;
  }
  return html`
    <div is="vl-form-grid" data-vl-is-stacked>
      ${filteredColumns.map((c) => renderFormColumn(c))}
    </div>`;
};

export const renderFormColumn = ({size = 12, template = html``} = {}) => {
  return html`
    <div is="vl-form-column" data-vl-size="${size}">
      ${template}
    </div>`;
};
