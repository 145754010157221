import {
  comparingWithFunction,
  define,
  formatDate,
  html,
  LitElement,
  unsafeHTML,
} from '../common/commons';
import {getResource, resolve} from '../common/rest';
import {
  renderFunctionalHeader,
  renderLayout,
  renderStack,
} from '../common/templates';

import '@domg-wc/elements/src/lib/grid';
import '@domg-wc/elements/src/lib/title';
import '@domg-wc/components/src/lib/alert';
import '@domg-wc/components/src/lib/loader';
import '@domg-wc/components/src/lib/typography';
import '@domg-wc/components/src/lib/info-tile';

class GrupsOverzicht extends LitElement {
  static get properties() {
    return {
      inspraakPeriodes: {type: Array},
    };
  }

  createRenderRoot() {
    return this;
  }

  async firstUpdated(_changedProperties) {
    await this.__loadInspraakPeriodes();
  }

  async __loadInspraakPeriodes() {
    const data = await getResource({url: '/api'});
    const searchLink = resolve(data._links, 'ibo');
    const response = await getResource({url: this.__createSearchUrl(searchLink)});
    this.inspraakPeriodes = response._embedded.inspraakPeriodeList;
  }

  __createSearchUrl(searchLink) {
    const url = new URL(searchLink);
    url.searchParams.append('filter',
      `periodeTotEnMet>=${this.__currentDate()};dossierType=='0d6264ed-6c51-4559-a0ee-eab1e7aed9c3'`);
    url.searchParams.append('page', '0');
    url.searchParams.append('size', '500');
    return url.href;
  }

  __currentDate() {
    return new Date().toISOString().split('T')[0];
  }

  render() {
    return html`
      ${renderFunctionalHeader('Gewestelijke ruimtelijke uitvoeringsplannen',
        'Nu inspreken over GRUPS', '/')}
      <section is="vl-region">
        ${renderLayout([renderStack(this.__contentColumns())])}
      </section>`;
  }

  __contentColumns() {
    return [
      {size: 12, template: this.__renderTitle()},
      {size: 8, template: this.__renderMeldingAantalInspraakperiodes()},
      {size: 8, template: this.__renderInspraakPeriodes()},
    ];
  }

  __renderTitle() {
    return html`<h1 is="vl-h1">Nu inspreken over GRUPS</h1>`;
  }

  __renderMeldingAantalInspraakperiodes() {
    if (!this.inspraakPeriodes) {
      return;
    }
    if (this.inspraakPeriodes.length === 0) {
      return html`
        <vl-alert
          data-vl-type="warning"
          data-vl-icon="alert-triangle"
          data-vl-title="Er zijn op dit moment geen lopende inspraakperiodes voor GRUPS.">
        </vl-alert>`;
    }
    return html`
      <vl-alert
        data-vl-type="success"
        data-vl-icon="info-circle"
        data-vl-title="Er zijn op dit moment ${this.inspraakPeriodes.length} lopende inspraakperiodes voor GRUPS.">
      </vl-alert>`;
  }

  __renderInspraakPeriodes() {
    if (!this.inspraakPeriodes) {
      return html`
        <vl-loader id="loader-resultaten"></vl-loader>`;
    }

    return renderStack(
      this.inspraakPeriodes.sort(this._ascendingByPeriodeTotEnMet())
      .flatMap((item) => this.__renderInspraakPeriode(item)),
    );
  }

  _ascendingByPeriodeTotEnMet() {
    return comparingWithFunction((ip) => ip.periodeTotEnMet);
  }

  __renderInspraakPeriode(inspraakPeriode) {
    const subTitle =
      `Inspraakperiode loopt van ${formatDate(
        inspraakPeriode.periodeVan)} tot en met ${formatDate(
        inspraakPeriode.periodeTotEnMet)}`;
    return {
      template: html`
        <vl-info-tile name="inspraakperiode-tile" data-vl-toggleable>
          <span slot="title">${inspraakPeriode.naam}</span>
          <span slot="subtitle"><strong style="font-size: large">${subTitle}</strong></span>
          <div slot="content">
            ${renderStack([
              {template: this.__renderOverDitDossier(inspraakPeriode)},
              {template: this.__renderBezwaren(inspraakPeriode)},
            ])}
          </div>
        </vl-info-tile>
      `,
    };
  }

  __renderOverDitDossier(inspraakPeriode) {
    return html`
      <h3 is="vl-h3" data-vl-sans>Over dit dossier</h3>
      <vl-typography>${unsafeHTML(inspraakPeriode.dossierInfo)}</vl-typography>
    `;
  }

  __renderBezwaren(inspraakPeriode) {
    return html`
      <h3 is="vl-h3" data-vl-sans>Bezwaren, opmerkingen en adviezen
        indienen</h3>
      <vl-typography>${unsafeHTML(inspraakPeriode.inspraakInstructies)}
      </vl-typography>
    `;
  }
}

define('grups-overzicht', GrupsOverzicht);
