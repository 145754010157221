import {define, html, LitElement, queryById, registerTranslateConfig, Router, use} from './common/commons';

import {vlElementsStyle} from '@domg-wc/elements';
import '@domg-wc/components/src/lib/template';

import './grups-accessibility';
import './grups-cookie';
import './grups-privacy';
import './components/grups-home';
import './components/grups-overzicht';
import './components/grups-zoeken';
import './components/grups-hoe-inspreken';
import './components/grups-inspraak-verwerking';
import './components/grups-bezwaar-verwerking';
import './components/grups-grup-procedure';
import './components/error/grups-view404';
import {renderFooter, renderHeader} from './common/templates';
import {config, resolveConfig} from './common/config';
import {resolveFicheRootEndpoint, resolveRootEndpoints} from './common/root-endpoints';

registerTranslateConfig({
  loader: (lang) =>
    fetch(`/i18n/${lang}.json`, {cache: 'reload'})
      .then((res) => res.json()),
});

document.adoptedStyleSheets = [...vlElementsStyle.map((style) => style.styleSheet)];

export class GrupsApp extends LitElement {
  static get properties() {
    return {
      appTitle: {type: String},
      _place: {type: String},
      _properties: {type: Object},
      _offline: {type: Boolean},
    };
  }

  static get styles() {
    return [];
  }

  constructor() {
    super();
  }

  firstUpdated(_changedProperties) {
    const router = new Router(queryById(this)('outlet'));
    router.setRoutes([
      {path: '/', component: 'grups-home'},
      {path: '/cookieverklaring', component: 'grups-cookie'},
      {path: '/privacy', component: 'grups-privacy'},
      {path: '/toegankelijkheid', component: 'grups-accessibility'},
      {path: '/overzicht', component: 'grups-overzicht'},
      {path: '/zoeken', component: 'grups-zoeken'},
      {path: '/hoe-inspreken', component: 'grups-hoe-inspreken'},
      {path: '/inspraak-verwerking', component: 'grups-inspraak-verwerking'},
      {path: '/bezwaar-verwerking', component: 'grups-bezwaar-verwerking'},
      {path: '/grup-procedure', component: 'grups-grup-procedure'},
      {path: '(.*)', component: 'grups-view404'},
    ]);
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <vl-template>
        ${renderHeader(config.globalTemplate)}
        <!-- Om ervoor te zorgen dat de footer onderaan het scherm staat indien 
           er weinig content is en mee naar beneden gaat als er veel content is.
           Header is 43px, footer is 128px -->
        <div style="min-height: calc(100vh - 43px - 128px)"
             slot="main" id="outlet"></div>
        ${renderFooter(config.globalTemplate)}
      </vl-template>
    `;
  }
}

Promise.all([
  resolveConfig(),
  resolveRootEndpoints().then(resolveFicheRootEndpoint),
  use('nl'),
]).then(() => {
  define('grups-app', GrupsApp);
});
