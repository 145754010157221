export const dossiersQuery = ({page, size, filter = '', sort = ''}) => {
  return {
    query: `
      {
        dossiers(page: ${page}, size: ${size}, filter: ${JSON.stringify(filter)}, sort: ${JSON.stringify(sort)}) {
          page
          size
          total
          content {
            id
            ficheLink
            naam
            omschrijving
            status
            thema
            datumBesluitTotGoedkeuring
            datumPublicatieBelgischStaatsblad
            fases {
              code
              payload
            }
          }
        }
      }`,
  };
};
