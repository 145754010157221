import {getResource, href} from './rest';
import {resolveFicheRootEndpoint, keuzelijstenLinks} from '../common/root-endpoints';

const SINGLETON = {};
export {
  SINGLETON as keuzelijsten,
};

export const resolveKeuzelijsten = async () => {
  if (SINGLETON.isLoaded) {
    return;
  }
  try {
    await resolveFicheRootEndpoint();
    for (const key of Object.keys(keuzelijstenLinks._links)) {
      const link = href(keuzelijstenLinks, key);
      const keuzelijst = await getResource({url: link});
      Object.assign(SINGLETON, keuzelijst._embedded);
    }
    SINGLETON.isLoaded = true;
  } catch (e) {
    SINGLETON.error = e;
    throw e;
  }
};
