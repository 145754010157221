import {define, html, LitElement, queryById} from './common/commons';
import '@domg-wc/sections/src/lib/accessibility';
import {renderFunctionalHeader} from './common/templates';

class GrupsAccessibility extends LitElement {
  constructor() {
    super();
  }

  firstUpdated(_changedProperties) {
    queryById(this)('accessibility').limitations = {
      'withTiming': [
        'De keuzelijst met ingebouwde zoekfunctie is niet bruikbaar met een screenreader. Criterium 4.1.2 Naam, rol, waarde.',
        'De pagina\'s hebben geen unieke en betekenisvolle titel. Criterium 2.4.2 Paginatitel.',
        'Bij paginawijzigingen wordt de focus van toetsenbord - en screenreadergebruikers niet verplaatst. Criterium 2.4.3 Focus volgorde.',
        'De website bevat HTML-validatiefouten met betrekking tot toegankelijkheid. Criterium 4.1.1 Parsen.',
        'De status van uitklapbare panelen (open of gesloten) wordt niet voorgelezen door screenreaders. Criterium 4.1.2 Naam, rol, waarde.',
        'Sommige teksten zijn onterecht als een statusbericht gecodeerd wat verwarrend is voor screenreadergebruikers. Criterium 4.1.3 Statusberichten.',
        'Bij het veranderen van een filter in de zoekpagina wordt de lijst met resultaten en het aantal gevonden resultaten automatisch aangepast. Die verandering wordt niet doorgegeven aan screenreaders. Criterium 4.1.3 Statusberichten.',
      ],
      'outsideApplicableLaw': [
        'Jira Service Desk formulieren',
        'Microsoft Forms',
      ],
    };
  }


  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <div slot="main">
        <vl-accessibility
          id="accessibility"
          data-vl-application="de website Gewestelijke Ruimtelijke Uitvoeringsplannen (GRUPS) van het departement Omgeving"
          data-vl-version="1.0.0"
          data-vl-date="29 september 2021"
          data-vl-date-modified="29 september 2021"
          data-vl-compliance="partially-compliant"
          data-vl-evaluation="expert-evaluated">
          ${renderFunctionalHeader('Departement Omgeving', 'Toegankelijkheid en gebruiksvoorwaarden', '/')}
        </vl-accessibility>
      </div>
    `;
  }
}

define('grups-accessibility', GrupsAccessibility);
