import {
  comparingWithFunction,
  debounce,
  define,
  findSearchParamValue,
  findSearchParamValues,
  formatDate,
  get,
  html,
  LitElement,
  queryById,
} from '../common/commons';
import {api, href, resolve} from '../common/rest';
import {
  buildContourFilter,
  buildInFaseFilter,
  buildThemaFilter,
  buildTrefwoordFilter, equal,
} from '../common/filter';
import {reduceFilterSpec} from '../common/rsql';
import {renderFormGrid, renderFunctionalHeader, renderLayout, renderStack} from '../common/templates';
import {formMixin} from '../common/forms';
import {checkbox, radio, select, text} from '../common/inputs';

import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {keuzelijsten, resolveKeuzelijsten} from '../common/keuzelijsten';
import {rootEndpoints} from '../common/root-endpoints';
import {dossiersQuery} from './graphql';

import '@domg-wc/elements/src/lib/grid';
import '@domg-wc/elements/src/lib/title';
import '@domg-wc/elements/src/lib/form';
import '@domg-wc/elements/src/lib/button';
import '@domg-wc/elements/src/lib/link';
import '@domg-wc/elements/src/lib/properties';

import '@domg-wc/components/src/lib/loader';
import '@domg-wc/components/src/lib/info-tile';
import '@domg-wc/components/src/lib/pager';
import '@domg-wc/components/src/lib/rich-data';
import '@domg-wc/components/src/lib/typography';

class GrupsZoeken extends formMixin(LitElement) {
  static get properties() {
    return {
      page: {type: Object},
      loading: {type: Boolean},
    };
  }

  constructor() {
    super();
    this.__debouncedSearch = debounce(
      {func: () => this.__search(), context: this, delay: 100});
    this.__searcher = null;

    this.__sort = GrupsZoeken.defaultSortKeuze;
    this.page = {
      paging: {
        currentPage: 1,
        itemsPerPage: 10,
      },
    };
    this.loading = true;
  }

  static get defaultSortKeuze() {
    return 'datumBesluitTotGoedkeuringSort,desc';
  }

  connectedCallback() {
    super.connectedCallback();
    try {
      this.__initKeuzelijsten();
    } catch (e) {
    }
  }

  async firstUpdated(_changedProperties) {
    try {
      this.__initFormData();
      // pas zoeken als formdata ingevuld is
      this.__debouncedSearch();
    } catch (e) {

    }
  }

  __initFormData() {
    this.formData = {
      'trefwoord': findSearchParamValue('trefwoord'),
      'status': findSearchParamValue('status') || '',
      'betrokken-gemeentes': findSearchParamValue('locatie'),
      'thema': findSearchParamValues('thema'),
      'fase': findSearchParamValue('fase'),
    };
  }

  __initKeuzelijsten() {
    this.__faseKeuzes = keuzelijsten['gewestelijkeRUPsFases'].map((f) => {
      return {label: this.__asFaseLabel(f.label), value: f.waarde};
    });
    this.__themaKeuzes = keuzelijsten['themas'].sort();
    this.__gemeenteKeuzes = keuzelijsten['gemeentes'].map((g) => {
      return {label: g.naam, value: g.niscode};
    });
  }

  updated(_changedProperties) {
    if (_changedProperties.has('page') && this.page) {
      this.byId('rich-data').data = this.page;
    }
  }

  get form() {
    return this.byId('search-form');
  }

  __extractPage(dossierPage) {
    return {
      data: dossierPage.content,
      paging: {
        currentPage: dossierPage.page,
        itemsPerPage: dossierPage.size,
        totalItems: dossierPage.total,
      },
    };
  }

  byId(id) {
    return queryById(this)(id);
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${renderFunctionalHeader('Gewestelijke ruimtelijke uitvoeringsplannen',
        'GRUPS zoeken', '/')}
      <section is="vl-region">
        ${renderLayout([this.__renderContent()])}
      </section>`;
  }

  __renderContent() {
    return renderStack([
      {size: 12, template: this.__renderTitel()},
      {
        size: 8,
        template: html`<h2 is="vl-h2">Overzicht gewestelijke ruimtelijke
          uitvoeringsplannen</h2>`,
      },
      {size: 12, template: this.__renderDataTable()},
    ]);
  }

  __renderTitel() {
    return html`
      <h1 is="vl-h1">GRUPS zoeken</h1>
      <p>
        Deze pagina bevat enkel ruimtelijke uitvoeringsplannen opgemaakt door de
        Vlaamse Overheid (GRUP).
        Ook provincies en gemeentes maken ruimtelijke uitvoeringsplannen. Bekijk
        deze plannen
        <a href="https://dsi.omgeving.vlaanderen.be/fiche-overzicht">hier</a> of
        raadpleeg de gemeente
        of de provincie voor een overzicht van de plannen op hun grondgebied.
      </p>
    `;
  }

  __renderDataTable() {
    // TODO data-vl-filter-closable moet hier eigenlijk niet
    //  (https://github.com/milieuinfo/webcomponent-vl-ui-rich-data/issues/14)
    return html`
      <vl-rich-data data-vl-filter-closable id="rich-data">
        <vl-pager slot="pager" @change="${this.__handlePageNumberChanged}"></vl-pager>
        <div slot="content">${this.__renderResultaten()}</div>
        <div slot="no-content">${this.__renderGeenResultaten()}</div>
        <select
          is="vl-select" id="sort-dropdown" name="sort-dropdown" slot="sorter"
          @change="${this.__handleSortOrderChanged}">
          ${(this.__renderSortKeuzes())}
        </select>
        <div is="vl-search-filter" data-vl-alt="" slot="filter">
          ${this.__renderSearchForm()}
        </div>
      </vl-rich-data>`;
  }

  __renderGeenResultaten() {
    if (this.loading) {
      return html`
        <vl-loader></vl-loader>`;
    }

    return html`
      <p>
        Er zijn geen gewestelijke ruimtelijke uitvoeringsplannen gevonden die
        aan uw huidige filter voldoen.
      </p>
    `;
  }

  __renderSearchForm() {
    return html`
      <form is="vl-form" id="search-form" novalidate
            @reset="${this.__handleSearchFormReset}"
            @submit="${(e) => e.preventDefault()}">

        ${this.__renderTrefwoord()}
        ${this.__renderLocatie()}
        ${this.__renderThema()}
        ${this.__renderFase()}
        ${this.__renderStatus()}
        ${this.__renderSearchButton()}
      </form>
      <div>
        <button is="vl-button-link" type="reset" form="search-form">
          Zoekopdracht verwijderen
        </button>
      </div>`;
  }

  __renderSortKeuzes() {
    return ['naam', 'thema',
      'datumBesluitTotGoedkeuring,desc',
      'actieveFaseTijdstipEerstePublicatie,desc']
    .map((sk) => this.__renderSortKeuze(sk));
  }

  __renderSortKeuze(sk) {
    return html`
      <option value="${sk}" ?selected="${sk === GrupsZoeken.defaultSortKeuze}">${get(
        `sortering.${sk}`)}
      </option>`;
  }

  __renderTrefwoord() {
    return html`
      <section>
        <h2>Doorzoek plannen</h2>
        ${renderFormGrid([
          text({
            label: 'Trefwoord',
            property: 'trefwoord',
            placeholder: 'Zoek op trefwoord',
            onChange: this.__handleSearchChanged,
          }),
        ])}
      </section>
    `;
  }

  __renderLocatie() {
    return html`
      <section>
        <h2>Locatie</h2>
        <div>
          Uitgebreid zoeken op locatie kan via ons
          <a is="vl-link"
             href="https://geoplannen.omgeving.vlaanderen.be/roviewer/?t=7&m=1&category=2">
            Geoportaal
          </a>
        </div>
        ${this.__renderGemeentesSelect()}
      </section>
    `;
  }

  __renderGemeentesSelect() {
    return renderFormGrid([
      select({
        label: 'Betrokken gemeente',
        property: 'betrokken-gemeentes',
        placeholder: 'Alle gemeentes',
        onChange: this.__handleSearchChanged,
        choices: this.__gemeenteKeuzes,
      })]);
  }

  __renderThema() {
    return html`
      <section>
        <h2>Thema</h2>
        ${renderFormGrid([
          checkbox({
            property: 'thema',
            choices: this.__themaKeuzes,
            onChange: this.__handleSearchChanged,
          }),
        ])}
      </section>`;
  }

  __renderFase() {
    return html`
      <section>
        <h2>Fase</h2>
        ${renderFormGrid([
          select({
            label: 'Fase',
            property: 'fase',
            placeholder: 'Alle fases',
            onChange: this.__handleSearchChanged,
            // serialize afzetten, anders werkt sorting function niet meer
            serialize: false,
            choices: this.__faseKeuzes,
            sortFilter: comparingWithFunction((x) => this.__volgorde(x.value)),
          }),
        ])}
      </section>
    `;
  }

  __renderStatus() {
    return html`
      <section>
        <h2>Status</h2>
        ${renderFormGrid([
          radio({
            property: 'status',
            label: 'Status',
            choices: [{
              label: 'Alle procedures',
              value: '',
              checked: true,
            }, {label: 'Definitief vastgestelde procedures', value: 'DEFINITIEF'}],
            onChange: this.__handleSearchChanged,
          }),
        ])}
      </section>
    `;
  }

  __renderSearchButton() {
    return html`
      <div>
        <button
          is="vl-button" id="search-button" type="submit"
          @click="${this.__debouncedSearch}">
          <span is="vl-icon" data-vl-icon="search" data-vl-before></span>
          Zoeken
        </button>
      </div>
    `;
  }

  __renderResultaten() {
    if (this.loading) {
      return html`
        <vl-loader></vl-loader>`;
    }

    return renderStack([
      ...this.page.data.map((item) => {
        return {template: this.__renderResultaat(item)};
      }),
    ]);
  }

  __renderResultaat(resultaat) {
    return html`
      <vl-info-tile data-vl-toggleable name="resultaat-info">
        <span slot="title">      
          ${resultaat.naam}
        </span>
        <span slot="subtitle">${resultaat.omschrijving}</span>
        <div slot="content">
          <vl-properties>
            <dl is="vl-properties-list">
              <dt is="vl-property-term">Fase</dt>
              <dd is="vl-property-value">
                ${this.__asFaseLabel(resultaat.fases[0].code)}
              </dd>

              <dt is="vl-property-term">Status</dt>
              <dd is="vl-property-value">
                ${this.__asStatusLabel(resultaat.status)}
              </dd>

              <dt is="vl-property-term">Thema</dt>
              <dd is="vl-property-value">${resultaat.thema}</dd>

              <dt is="vl-property-term">Datum besluit tot goedkeuring door
                Vlaamse Regering
              </dt>
              <dd is="vl-property-value">
                ${resultaat.datumBesluitTotGoedkeuring || ''}
              </dd>

              <dt is="vl-property-term">Datum Publicatie Belgisch Staatsblad
              </dt>
              <dd is="vl-property-value">
                ${resultaat.datumPublicatieBelgischStaatsblad || ''}
              </dd>
            </dl>
          </vl-properties>
          ${this.__renderFaseInfo(resultaat)}
          <!--TODO -->
          <a is="vl-link-button" href="${this.__getLink(resultaat.id)}">
            <span is="vl-icon" data-vl-icon="external"
                  data-vl-before></span>
            Meer info
          </a>
        </div>
      </vl-info-tile>
    `;
  }

  __getLink(dossierId) {
    return resolve(rootEndpoints, 'dossierSearchEndpoint', {
      'dossierId': dossierId,
    });
  }


  __renderFaseInfo(resultaat) {
    if (resultaat.actieveFaseInformatie == null ||
      resultaat.actieveFaseInformatie === '') {
      return html``;
    }
    return html`
      <vl-typography>
        ${unsafeHTML(resultaat.actieveFaseInformatie || '')}
      </vl-typography>
      </br>`;
  }

  __formatDate(date) {
    if (date) {
      return formatDate(date);
    }
    return '';
  }

  __handlePageNumberChanged(e) {
    if (this.page.paging.currentPage !== e.detail.currentPage) {
      this.page = Object.assign(this.page, {
        paging: {
          currentPage: e.detail.currentPage,
          itemsPerPage: this.page.paging.itemsPerPage,
          totalItems: this.page.paging.totalItems,
        },
      });
      this.__debouncedSearch();
    }
  }

  __handleSortOrderChanged(e) {
    this.__sort = e.target ? e.target.value : GrupsZoeken.defaultSortKeuze;
    this.__debouncedSearch();
  }

  __handleSearchFormReset() {
    this.formData = {};
    // formdata resettten zou voldoende zijn, daar je dan een change event
    // verwacht van de form dit is niet zo voor alle componenten, dus moeten
    // we hier expliciet nog de pager resetten en de search triggeren
    this.__resetPager();
    this.__debouncedSearch();
  }

  __handleSearchChanged(e) {
    this.__resetPager();
    this.__debouncedSearch();
  }

  __resetPager() {
    this.page = Object.assign(this.page, {
      paging: {
        currentPage: 1,
        itemsPerPage: this.page.paging.itemsPerPage,
        totalItems: this.page.totalItems,
      },
    });
  }

  async __search() {
    this.loading = true;
    this.__updateCurrentLocation();
    const graph = await this.__searchApi().post({
      url: href(rootEndpoints, 'dsiGraphqlEndpoint'),
      data: dossiersQuery({
        page: this.page.paging.currentPage,
        size: this.page.paging.itemsPerPage,
        filter: this.__createFilterSpec(),
        sort: this.__sort,
      }),
    });
    this.page = this.__extractPage(graph.data.dossiers);
    this.loading = false;
  }

  __searchApi() {
    if (this.__searcher) {
      this.__searcher.abort();
    }
    this.__searcher = api();
    return this.__searcher;
  }

  __createFilterSpec() {
    const formData = this.formData;
    return reduceFilterSpec({
      trefwoord: {
        value: formData.trefwoord,
        filter: buildTrefwoordFilter,
      },
      code: {
        value: 'gewRUP',
        filter: equal('code'),
      },
      themas: {
        value: formData.thema,
        filter: buildThemaFilter,
      },
      status: {
        value: formData.status,
        filter: equal('status'),
      },
      fase: {
        value: formData.fase,
        filter: buildInFaseFilter,
      },
      betrokkenGemeente: {
        value: formData['betrokken-gemeentes'],
        filter: buildContourFilter,
      },
    });
  }
  __updateCurrentLocation() {
    const currentLocation = new URL(window.location.href);
    const searchParams = currentLocation.searchParams;
    this.__updatesearchParamSingleValue(searchParams, 'trefwoord', this.formData['trefwoord']);
    this.__updatesearchParamSingleValue(searchParams, 'locatie', this.formData['betrokken-gemeentes']);
    this.__updatesearchParamMultiValue(searchParams, 'thema', this.formData['thema']);
    this.__updatesearchParamSingleValue(searchParams, 'fase', this.formData['fase']);
    this.__updatesearchParamSingleValue(searchParams, 'status', this.formData['status']);
    window.history.replaceState({}, '', currentLocation.href);
  }

  __updatesearchParamSingleValue(searchParams, key, value) {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
  }

  __updatesearchParamMultiValue(searchParams, key, values) {
    searchParams.delete(key);
    if (values) {
      values.forEach((value) => searchParams.append(key, value));
    }
  }

  __asFaseLabel(value) {
    return get(`fases.${value}`);
  }

  __asStatusLabel(value) {
    return get(`statussen.${value}`);
  }

  __volgorde(code) {
    switch (code) {
      case 'ST':
        return 1;
      case 'SP':
        return 2;
      case 'PV':
        return 3;
      case 'VV':
        return 4;
      case 'BG':
        return 5;
      case 'NA':
        return 6;
      default:
        return 7;
    }
  }
}

Promise.all([
  resolveKeuzelijsten(),
  window.customElements.whenDefined('vl-select'),
]).then(() => {
  define('grups-zoeken', GrupsZoeken);
});
