import {GrupsFooterStyle} from './grups-footer-styles';
import {define, html, LitElement} from '../common/commons';

class GrupsFooter extends LitElement {
  static get styles() {
    return [GrupsFooterStyle];
  }

  render() {
    return html`
      <div id="footer">
        <div class="vlw">
          <footer class="vlw__footer">
            <div class="vlw__footer__container">
              <div class="vlw__footer__col vlw__footer__umbrella">
                <img src="/images/vlaanderen-logo.svg"
                     srcset="" alt="logo Vlaanderen" class="vlw__footer__umbrella__logo">
              </div>
              <div class="vlw__footer__col vlw__footer__body">
                <div>
                  <h2 class="vlw__footer__body__title">
                    Vlaanderen.be is de officiële website van de Vlaamse overheid
                    <span class="vlw__footer__body__subtitle">
                      <span>uitgegeven door Informatie Vlaanderen</span>
                    </span>
                  </h2>
                </div>

                <ul class="vlw__footer__body__links">
                  <li class="vlw__footer__body__links__item">
                    <a href="/privacy" target="_blank"
                       class="vlw__footer__body__links__item__cta">Privacy</a>
                  </li>
                  <li class="vlw__footer__body__links__item">
                    <a href="/toegankelijkheid" target="_blank"
                       class="vlw__footer__body__links__item__cta">Toegankelijkheid</a>
                  </li>
                  <li class="vlw__footer__body__links__item">
                    <a href="/cookieverklaring" target="_blank"
                       class="vlw__footer__body__links__item__cta">Cookieverklaring</a>
                  </li>
                </ul>

                <ul class="vlw__footer__language vlw__footer__language--mobile">
                  <li class="vlw__footer__language__item">
                    <a href="/" hreflang="nl" lang="nl" rel="alternate"
                       class="vlw__footer__language__item__cta vlw__footer__language__item__cta--is-selected">
                      <span aria-hidden="true">nl</span>
                      <span class="vlw__u-visually-hidden">Nederlands</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="vlw__footer__col vlw__footer__side">
                <ul class="vlw__footer__language vlw__footer__language--desktop">
                  <li class="vlw__footer__language__item">
                    <a href="/" hreflang="nl" lang="nl" rel="alternate"
                       class="vlw__footer__language__item__cta vlw__footer__language__item__cta--is-selected">
                      <span aria-hidden="true">nl</span>
                      <span class="vlw__u-visually-hidden">Nederlands</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
      </div>`;
  }
}

define('grups-footer', GrupsFooter);
