import {css, html, LitElement, render} from 'lit';
import {define} from '@domg-wc/common-utilities';
import {Router} from '@vaadin/router';
import {
  get,
  registerTranslateConfig,
  translate,
  use,
} from 'lit-translate';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {ifDefined} from 'lit/directives/if-defined.js';

export {
  css,
  get,
  registerTranslateConfig,
  translate,
  use,
  unsafeHTML,
  ifDefined,
};

const SUPPORTED_LANGUAGES = ['nl'];

// Convenient methods

export const queryById = (component) => (id) => {
  return queryBySelector(component)(`[id="${id}"]`);
};

export const queryByName = (component) => (name) => {
  return queryBySelectorAll(component)(`[name="${name}"]`);
};

export const queryBySelector = (component) => (id) => {
  if (!component.shadowRoot) {
    return component.querySelector(id);
  }
  return component.shadowRoot.querySelector(id);
};

export const queryBySelectorAll = (component) => (id) => {
  if (!component.shadowRoot) {
    return component.querySelectorAll(id);
  }
  return component.shadowRoot.querySelectorAll(id);
};

const getUserPreferredLanguage = () => {
  if (SUPPORTED_LANGUAGES.includes(navigator.language)) {
    return navigator.language;
  }
  return SUPPORTED_LANGUAGES[0];
};

export const formatDate = (date) => {
  return new Intl.DateTimeFormat(getUserPreferredLanguage(),
    {year: 'numeric', month: 'long', day: 'numeric'}).format(new Date(date));
};

export const comparingWithFunction = (fn) => (a, b) => {
  const fa = fn(a);
  const fb = fn(b);
  return fa === fb ? 0 : fa < fb ? -1 : 1;
};

export {LitElement, html};
export {render};
export {define};
export {Router};

export const findSearchParamValue = (searchParam) => {
  const currentLocation = new URL(window.location.href);
  const searchParams = currentLocation.searchParams;
  if (searchParams.has(searchParam)) {
    return searchParams.get(searchParam);
  }
  return null;
};

export const findSearchParamValues = (searchParam) => {
  const currentLocation = new URL(window.location.href);
  const searchParams = currentLocation.searchParams;
  if (searchParams.has(searchParam)) {
    return searchParams.getAll(searchParam);
  }
  return [];
};

export const debounce = ({func, delay = 100, context}) => {
  let timerId;
  return (...args) => {
    const boundFunc = func.bind(context, ...args);
    clearTimeout(timerId);
    timerId = setTimeout(boundFunc, delay);
  };
};
