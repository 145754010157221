import {define, html, LitElement} from './common/commons';

import '@domg-wc/sections/src/lib/privacy';
import {renderFunctionalHeader} from './common/templates';

class GrupsPrivacy extends LitElement {
  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <div slot="main">
        <vl-privacy>
          ${renderFunctionalHeader('Departement Omgeving', 'Privacy', '/')}
        </vl-privacy>
      </div>
    `;
  }
}


define('grups-privacy', GrupsPrivacy);
