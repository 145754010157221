import {define, html, LitElement} from '../common/commons';
import {
  renderFunctionalHeader,
  renderLayout,
  renderRow,
} from '../common/templates';

import '@domg-wc/elements/src/lib/grid';
import '@domg-wc/elements/src/lib/title';

class GrupsGrupProcedure extends LitElement {
  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${renderFunctionalHeader('Gewestelijke ruimtelijke uitvoeringsplannen',
        'Wat is een Gewestelijk Ruimtelijk Uitvoeringsplan', '/overzicht')}
      <section is="vl-region">
        ${renderLayout([this.__renderContent()])}
      </section>`;
  }

  __renderContent() {
    return renderRow([
      {size: 12, template: this.__renderTitle()},
      {size: 12, template: this.__renderInhoud()},
    ]);
  }

  __renderTitle() {
    return html`
      <h1 is="vl-h1">Wat is een Gewestelijk Ruimtelijk Uitvoeringsplan</h1>
    `;
  }

  __renderInhoud() {
    return html`
      <vl-typography>
        <h2>Proces</h2>
        <p>
          Een ruimtelijk uitvoeringsplan komt tot stand in een proces van
          visie- en besluitvorming voor een gebied. Daarbij worden de
          ruimtelijke behoeften van de verschillende maatschappelijke
          activiteiten gelijktijdig tegen elkaar afgewogen. Er wordt rekening
          gehouden met de ruimtelijke draagkracht, de gevolgen voor het
          leefmilieu en de culturele, economische, esthetische en sociale
          gevolgen.
        </p>
        <p>
          Het proces van visievorming bepaalt voor de ruimte in het plangebied
          het meest geschikte inrichting en de ruimtelijke kwaliteit. Een
          ruimtelijk uitvoeringsplan geeft uitvoering aan een ruimtelijk
          structuurplan.
        </p>
        <p>
          Een ruimtelijk uitvoeringsplan komt tot stand in overleg met bewoners,
          gebruikers en eigenaars van gronden, met actoren, administraties en
          besturen.
        </p>
        <h2>Procedure</h2>
        <p>
          De opmaak van een GRUP doorloopt een vast omschreven procedure.
          De procedure voor de opmaak van een gewestelijk ruimtelijk
          uitvoeringsplan is wettelijk geregeld in de Vlaamse Codex
          Ruimtelijke Ordening (VCRO). De VCRO voorziet vijf fases of stappen:
        <ol>
          <li>startnota</li>
          <li>scoping</li>
          <li>voorontwerp-RUP</li>
          <li>ontwerp-RUP</li>
          <li>definitief RUP</li>
        </ol>
        </p>
        <p>
          In alle fases van de procedure kan inspraak georganiseerd worden.
          In twee van die stappen is een formele inspraakperiode wettelijk
          verplicht: over de startnota en over het ontwerp-RUP.
        </p>
        <h2>Product</h2>
        <p>
          Een ruimtelijk uitvoeringsplan legt voor het plangebied vast:
        <ul>
          <li>Welke activiteiten er mogen plaatsvinden</li>
          <li>Waar er mag gebouwd worden</li>
          <li>Aan welke stedenbouwkundige voorschriften constructies moeten voldoen</li>
        </ul>
        </p>
        <p>
          De toegelaten gebouwen en activiteiten worden aangeduid op kaart
          (grafisch plan met zones). Bij elke zone wordt in de
          stedenbouwkundige voorschriften omschreven welke regels van
          toepassing zijn in dat gebied.
        </p>
        <p>
          Een ruimtelijk uitvoeringsplan heeft een verordenende, dus
          verplichtende waarde voor iedereen. Dit betekent concreet dat een
          omgevingsvergunning slechts kan worden verleend als de beoogde
          ingreep in overeenstemming is met het ruimtelijk uitvoeringsplan
          op die plaats.
        </p>
      </vl-typography>
    `;
  }
}

define('grups-grup-procedure', GrupsGrupProcedure);
