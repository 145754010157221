import {getResource, href} from './rest';

const SINGLETON = {};
export {
  SINGLETON as rootEndpoints,
};

export const resolveRootEndpoints = async () => {
  if (SINGLETON.isLoaded) {
    return;
  }
  try {
    const resource = await getResource({url: '/api'});
    SINGLETON._links = {};
    Object.assign(SINGLETON._links, resource._links);
    SINGLETON.isLoaded = true;
  } catch (e) {
    SINGLETON.error = e;
    throw e;
  }
};

const KEUZELIJSTEN_SINGLETON = {};
export {
  KEUZELIJSTEN_SINGLETON as keuzelijstenLinks,
};

export const resolveFicheRootEndpoint = async () => {
  if (KEUZELIJSTEN_SINGLETON.isLoaded) {
    return;
  }
  try {
    await resolveRootEndpoints();
    const dsiKeuzelijstEndpoint = href(SINGLETON, 'dsiKeuzelijstEndpoint');
    const resource = await getResource({url: dsiKeuzelijstEndpoint});
    KEUZELIJSTEN_SINGLETON._links = {};
    Object.assign(KEUZELIJSTEN_SINGLETON._links, resource._links);
    KEUZELIJSTEN_SINGLETON.isLoaded = true;
  } catch (e) {
    KEUZELIJSTEN_SINGLETON.error = e;
    throw e;
  }
};
