import {html, LitElement, define} from '../../common/commons';
import {renderLayout} from '../../common/templates';

class GrupsView404 extends LitElement {
  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <section is="vl-region">
        ${renderLayout([this.__render404Message()])}
      </section>`;
  }

  __render404Message() {
    return html`
      <vl-http-404-message id="not-found-message">
        <div slot="actions">
          <a is="vl-link-button" href="/">Terug naar de startpagina</a>
        </div>
      </vl-http-404-message>
    `;
  }
}

define('grups-view404', GrupsView404);
