export const buildContourFilter = (contour) => {
  return __inGemeenteValue('actieveFaseContour', contour);
};

export const buildTrefwoordFilter = (trefwoord) => {
  return {
    or: [
      __likeValue('naam', trefwoord),
      __likeValue('omschrijving', trefwoord),
      __dossierInformatieValue('dossierInformatie', trefwoord),
    ],
  };
};

export const buildThemaFilter = (themas) => {
  return __inValue('thema', themas);
};

export const buildInFaseFilter = (value) => {
  return __inFaseValue('code', value);
};

export const equal = (name) => (value) => {
  return __eqValue(name, value);
};

const __eqValue = (name, value) => __value(name, '==', value);
const __likeValue = (name, value) => __value(name, '=like=', value);
const __inValue = (name, value) => __arrayValue(name, '=in=', value);
const __inGemeenteValue = (name, value) => __value(name, '=ingemeente=', value);
const __inFaseValue = (name, value) => __value(name, '=infase=', value);
const __dossierInformatieValue = (name, value) => __value(name, '=ldi=', value);

const __value = (name, operator, value) => {
  return {'name': name, 'operator': operator, 'value': JSON.stringify(value)};
};

const __arrayValue = (name, operator, array) => {
  return {'name': name, 'operator': operator, 'value': `(${array.map((value) => JSON.stringify(value))})`};
};
