import {define, html, LitElement} from '../common/commons';
import {
  renderFunctionalHeader,
  renderLayout,
  renderRow,
} from '../common/templates';

import '@domg-wc/elements/src/lib/title';
import '@domg-wc/elements/src/lib/grid';
import '@domg-wc/components/src/lib/typography';

class GrupsBezwaarVerwerking extends LitElement {
  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${renderFunctionalHeader('Gewestelijke ruimtelijke uitvoeringsplannen',
        'Wat gebeurt er met mijn bezwaar', '/overzicht')}
      <section is="vl-region">
        ${renderLayout([this.__renderContent()])}
      </section>`;
  }

  __renderContent() {
    return renderRow([
      {size: 12, template: this.__renderTitle()},
      {size: 12, template: this.__renderInhoud()},
    ]);
  }

  __renderTitle() {
    return html`
      <h1 is="vl-h1">Wat gebeurt er met mijn bezwaar</h1>
    `;
  }

  __renderInhoud() {
    return html`
      <vl-typography>
        <h2 is="vl-h2">Inspraakreactie versus bezwaar</h2>
        <p>
          De procedure voor de opmaak van een gewestelijk ruimtelijk
          uitvoeringsplan is wettelijk geregeld in de Vlaamse Codex Ruimtelijke
          Ordening (VCRO). De VCRO voorziet vijf fases of stappen:
        <ol>
          <li>startnota</li>
          <li>scoping</li>
          <li>voorontwerp-RUP</li>
          <li>ontwerp-RUP</li>
          <li>definitief RUP</li>
        </ol>
        </p>
        <p>
          In alle fases van de procedure kan inspraak georganiseerd worden. In
          twee van die stappen is een formele inspraakperiode wettelijk verplicht:
        <ul>
          <li>
            Fase startnota. De Vlaamse Regering keurt een startnota goed en
            legt de startnota voor in een <b>raadpleging van de bevolking</b>.
            Op dat ogenblik kan iedereen een <b>inspraakreactie</b> indienen.
            Het gaat hier om inspraak in een zeer vroege fase van het proces
            waarbij de planopties nog niet vastliggen en nog onderzoek nodig is.
          </li>
          <li>
            Fase ontwerp. Na de voorlopige vaststelling van een ruimtelijk
            uitvoeringsplan wordt een <b>openbaar onderzoek</b> georganiseerd.
            Tijdens dit openbaar onderzoek kan iedereen een <b>bezwaar</b>
            indienen over het ontwerp-RUP. Hier gaat het om inspraak over
            een RUP dat alle informatie bevat zodat er inspraak mogelijk is
            over het geheel van het plan vooraleer het finaal wordt goedgekeurd.
          </li>
        </ul>
        </p>
        <h2 is="vl-h2">Verwerking van bezwaar over een ontwerp-RUP</h2>
        <p>
          Bezwaren kunnen op verschillende manieren worden ingediend: digitaal
          via de website, op papier, via de gemeente, bij het Departement
          Omgeving. Alle reacties worden bijeengebracht en op dezelfde manier
          verwerkt. Waar of hoe ze zijn ingediend maakt dus geen verschil.
        </p>
        <p>
          De verwerking van de bezwaren wordt voorbereid door het planteam.
          Het planteam onderzoekt en beoordeelt alle bezwaren. Over alle
          onderdelen en aspecten van het ontwerp-RUP kan een bezwaar worden
          ingediend. Het planteam bundelt alle inspraakreacties en groepeert
          gelijkaardige opmerkingen. De beoordeling gebeurt ook gegroepeerd
          en niet op individuele basis per reactie.
        </p>
        <p>
          Het planteam kan oordelen dat een bezwaar terecht is en kan in
          navolging daarvan een aanpassing aan het plan voorstellen. Het
          planteam kan oordelen dat een bezwaar terecht is maar dat de
          vereiste aanpassing zo fundamenteel is, dat er een nieuw ontwerp-RUP
          moet worden opgemaakt (= terug naar start) waarna een nieuw openbaar
          onderzoek volgt. Het planteam kan oordelen dat een bezwaar niet
          terecht is en voorstellen om het plan niet aan te passen. Het
          planteam motiveert haar beoordeling. Ze maakt dus duidelijk waarom
          ze tot een bepaalde beoordeling of keuze komt.
        </p>
        <p>
          Nadat het planteam de beoordeling heeft afgerond wordt het plan
          opnieuw voorgelegd aan de Vlaamse Regering die de beslissing neemt
          om het plan al dan niet aan te passen en definitief goed te keuren.
          De Vlaamse Regering beschikt over een termijn van 180 dagen
          (inclusief de voorbereiding door het planteam). Die beslissing
          wordt eveneens gemotiveerd. De motivering is te vinden in het besluit
          van de Vlaamse Regering. Dat besluit wordt gepubliceerd in het
          Belgisch Staatsblad en op de website van het Departement Omgeving.
          Bezwaarindieners kunnen in dat besluit terugvinden hoe hun bezwaar
          is beoordeeld. Het is dus niet zo dat je een bericht krijgt met de
          verwerking van je inspraakreactie. Het goedkeuringsbesluit en het
          definitieve plan kan je ook inkijken bij de gemeente.
        </p>
      </vl-typography>
    `;
  }
}

define('grups-bezwaar-verwerking', GrupsBezwaarVerwerking);
