const EXPANSION_REGEX = /(\{)[^{]*(\})/g;

const DEFAULT_OPERATOR = {
  operator: '', separator: ',', named: false, ifEmpty: false,
};

const OPERATORS = [{operator: '#', separator: ',', named: false, ifEmpty: false}, {
  operator: '.', separator: '.', named: false, ifEmpty: false,
}, {operator: '/', separator: '/', named: false, ifEmpty: false}, {
  operator: ';', separator: ';', named: true, ifEmpty: false,
}, {operator: '?', separator: '&', named: true, ifEmpty: true}, {
  operator: '&', separator: '&', named: true, ifEmpty: true,
}];

const getNamedOperatorIfNeeded = (operator, varName, el) => {
  if (operator.named) {
    return `${varName}=${el}`;
  }
  return el;
};

const getValueFor = (params, varName, operator) =>
  [].concat(params[varName])
  .reduce((acc, el) => acc.concat(getNamedOperatorIfNeeded(operator, varName, el)), [])
  .join(operator.separator);

const containsParam = (params, v, canBeEmpty) => {
  if (canBeEmpty) {
    return Boolean(params && typeof params[v] !== 'undefined');
  }
  return Boolean(params && params[v]);
};

const startsWith = (str, needle) => str.indexOf(needle) === 0;

const getLinkOptions = (link) => {
  const groups = link.match(EXPANSION_REGEX);
  if (groups) {
    return {
      url: link, options: groups
      // need to slice of the surrounding '{' and '}' -> slice(1, -1)
      .reduce((acc, str) => acc.concat(str.slice(1, -1).split(',')), [])
      .map((str) => {
        if (OPERATORS.some((operator) => startsWith(str, operator.operator))) {
          return str.slice(1);
        }
        return str;
      }),
    };
  }
  return {
    url: link, options: [],
  };
};

const translateArgumentsWithOperator = (str, params, operator) =>
  str.split(',')
  .filter((v) => containsParam(params, v, operator.ifEmpty))
  .map((v) => getValueFor(params, v, operator))
  .join(operator.separator);

const translateTemplatedLink = (link, params) => {
  const groups = link.match(EXPANSION_REGEX);
  if (!groups) {
    return link;
  }
  return groups.reduce((returnLink, str) => {
    // need to slice of the surrounding '{' and '}' -> slice(1, -1)
    const slicedStr = str.slice(1, -1);
    const matchedOperator = OPERATORS.find((operator) => startsWith(slicedStr, operator.operator));
    const operator = matchedOperator || DEFAULT_OPERATOR;
    if (!matchedOperator) {
      return returnLink.replace(`{${slicedStr}}`, translateArgumentsWithOperator(slicedStr, params, operator));
    }
    const argumentList = translateArgumentsWithOperator(slicedStr.slice(1), params, operator);
    return returnLink.replace(`{${slicedStr}}`, `${argumentList.length > 0 ? operator.operator : ''}${argumentList}`);
  }, link);
};

const translateLink = (link, params) => {
  if (!link.href) {
    return null;
  }
  if (link.templated) {
    return translateTemplatedLink(link.href, params);
  }
  return link.href;
};

const getMatchQuotient = (options, params) => params ? options.filter((option) => option in params).length : 0;

const findBestTemplatedLinkForParams = (linkList, params) =>
  linkList.map((mapLink) => mapLink.href)
  .filter((mapLink) => mapLink)
  .map(getLinkOptions)
  .reduce((bestLink, currentLink) => {
    const linkDescriptor = {
      ...currentLink, matchQuotient: getMatchQuotient(currentLink.options, params),
    };
    if (!bestLink || linkDescriptor.matchQuotient > bestLink.matchQuotient) {
      return linkDescriptor;
    }
    return bestLink;
  }, null);

const translateArrayIntoLink = (linkList, params) => {
  if (!params) {
    const link = linkList.find((linkCheck) => !linkCheck.templated);
    if (link) {
      return translateLink(link, params);
    }
  }
  const link = findBestTemplatedLinkForParams(linkList, params);
  return link ? translateTemplatedLink(link.url, params) : null;
};

const resolveLinksArray = (links, linkName) => {
  const link = links.find((item) => item.rel === linkName);
  return (link && link.href) || null;
};

const resolveNamedLink = (link, params) => {
  if (typeof link === 'string') {
    return link;
  } else if (link instanceof Array) {
    if (link.length > 0) {
      return translateArrayIntoLink(link, params);
    }
  } else if (link instanceof Object) {
    return translateLink(link, params);
  }
  return null;
};

const getObjectLinks = (object) => object._links || object.links || object;

const resolveLinksObject = (object, linkName, params) => {
  const links = getObjectLinks(object);
  if (links instanceof Array) {
    return resolveLinksArray(links, linkName);
  }
  if (links[linkName]) {
    return resolveNamedLink(links[linkName], params);
  }
  return null;
};

export const resolve = (object, linkName, params) => {
  if (!linkName) {
    throw new Error('No link name was passed to hal link resolver');
  }
  return object ? resolveLinksObject(object, linkName, params) : null;
};

class Fetcher {
  constructor({credentials, headers}) {
    this.__config = {credentials, headers};
    this.__controller = new AbortController();
  }

  abort() {
    this.__controller.abort();
  }

  async get({url, headers}) {
    return this.__handleRequest({
      method: 'GET', url, headers,
    });
  }

  async post({url, data, headers}) {
    return this.__handleRequest({
      method: 'POST', url, data, headers,
    });
  }

  async put({url, data, headers}) {
    return this.__handleRequest({
      method: 'PUT', url, data, headers,
    });
  }

  async patch({url, data, headers}) {
    return this.__handleRequest({
      method: 'PATCH', url, data, headers,
    });
  }

  async delete({url, headers}) {
    return this.__handleRequest({
      method: 'DELETE', url, headers,
    });
  }

  async postForm({url, data, headers}) {
    const response = await this.__handleFormRequest({data, headers, url});
    return this.__handleResponse(response);
  }

  async __handleRequest({method, url, data, headers}) {
    const response = await this.__handleJSONRequest({data, headers, url, method});
    return this.__handleResponse(response);
  }

  async __handleFormRequest({data, headers, url}) {
    return await fetch(url, {
      method: 'POST',
      body: data,
      credentials: this.__config.credentials,
      headers: headers,
      redirect: 'follow',
      signal: this.__controller.signal,
    });
  }

  async __handleJSONRequest({data, headers, url, method}) {
    return await fetch(url, {
      method: method,
      body: JSON.stringify(data),
      credentials: this.__config.credentials,
      headers: Object.assign({}, this.__config.headers, headers),
      redirect: 'follow',
      signal: this.__controller.signal,
    });
  }

  __handleResponse(response) {
    if (!response.ok) {
      const err = new Error(response.statusText);
      err.response = response;
      throw err;
    }

    if (response.status === 204) {
      // return nothing as there is no content
      return;
    }

    return response.json();
  }

  static create({credentials, headers}) {
    return new Fetcher({credentials, headers});
  }

  static createDefault() {
    return new Fetcher({
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/hal+json, application/json', // receive json
        'Content-Type': 'application/json', // send json
      },
    });
  }
}

// stateful api versie, om in flight requests te kunnen annuleren
export const api = () => {
  return Fetcher.createDefault();
};

export const getResource = ({url, headers}) => {
  return api().get({url, headers});
};

export const hasLink = (resource, naam) => {
  return resource._links != null && resource._links[naam] != null;
};

export const href = (resource, naam) => {
  if (hasLink(resource, naam)) {
    // Als lijst leeg is, komen er 2 links in de relatie -> bug Empty Many resource processor
    if (resource._links[naam].length) {
      return resource._links[naam][0].href;
    }
    return resource._links[naam].href;
  }
  return null;
};
