import {define, html, LitElement} from '../common/commons';
import {
  renderFunctionalHeader,
  renderLayout,
  renderRow,
} from '../common/templates';

import '@domg-wc/elements/src/lib/grid';
import '@domg-wc/elements/src/lib/title';
import '@domg-wc/elements/src/lib/link';

class GrupsHoeInspreken extends LitElement {
  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      ${renderFunctionalHeader('Gewestelijke ruimtelijke uitvoeringsplannen',
        'Hoe kan ik een inspraakreactie indienen', '/overzicht')}
      <section is="vl-region">
        ${renderLayout([this.__renderContent()])}
      </section>`;
  }

  __renderContent() {
    return renderRow([
      {size: 12, template: this.__renderTitle()},
      {size: 12, template: this.__renderInhoud()},
    ]);
  }

  __renderTitle() {
    return html`
      <h1 is="vl-h1">Hoe kan ik een inspraakreactie indienen</h1>
    `;
  }

  __renderInhoud() {
    return html`
      <vl-typography>
        <h2 is="vl-h2">Wanneer</h2>
        <p>
          De procedure voor de opmaak van een ruimtelijk uitvoeringsplan is
          wettelijk geregeld in de Vlaamse Codex Ruimtelijke Ordening (VCRO).
          De VCRO voorziet vijf fases of stappen:
        <ol>
          <li>startnota</li>
          <li>scoping</li>
          <li>voorontwerp-RUP</li>
          <li>ontwerp-RUP</li>
          <li>definitief RUP</li>
        </ol>
        In alle fases van de procedure kan inspraak georganiseerd worden.
        </p>
        <p>
          In twee van die stappen is een formele inspraakperiode wettelijk
          verplicht:
        <ul>
          <li>
            Fase startnota. De Vlaamse Regering keurt een startnota goed
            en legt de startnota voor in een
            <b>raadpleging van de bevolking</b>.
            Op dat ogenblik kan iedereen een inspraakreactie indienen.
            Het gaat hier om inspraak in een zeer vroege fase van het proces
            waarbij nog veel onderzoek nodig is. In deze fase worden ook
            advies gevraagd bij besturen en administraties.
          </li>
          <li>
            Fase ontwerp. Na de voorlopige vaststelling van een ruimtelijk
            uitvoeringsplan wordt een <b>openbaar onderzoek</b> georganiseerd.
            Tijdens dit openbaar onderzoek kan iedereen een bezwaar indienen
            over het ontwerp-RUP. Hier gaat het om inspraak over een RUP dat
            alle informatie bevat zodat er inspraak mogelijk is over het
            geheel van het plan vooraleer het finaal wordt goedgekeurd.
            Er wordt in deze fase ook advies gevraagd bij besturen en
            administraties.
          </li>
        </ul>
        </p>
        <h2 is="vl-h2">Wie</h2>
        <p>
          Iedereen kan een inspraakreactie indienen. Je kan individueel
          inspreken, of als vereniging, of als bedrijf.
          Je hoeft niet binnen het plangebied te wonen. Iedereen dus.
        </p>
        <h2 is="vl-h2">Hoe</h2>
        <p>
          Een inspraakreactie moet je schriftelijk indienen. In de praktijk
          worden voor GRUPS volgende mogelijkheden aangeboden waarbij je als
          indiener verzekerd bent dat je reactie goed ontvangen wordt en dat
          er verder rekening mee zal worden gehouden:
        <ol>
          <li>
            Via een online formulier waarvan u de link kan terugvinden
            op de <a is="vl-link" href="/overzicht">website</a> van het
            Departement Omgeving. Je kan een bijlage toevoegen aan het
            formulier. Je krijgt een digitaal ontvangstbewijs
            teruggestuurd.
          </li>
          <li>
            Per brief naar Departement Omgeving, Graaf de Ferrarisgebouw,
            Koning Albert II-laan 20 bus 7, 1000 Brussel. Met een aangetekende
            brief heb je bewijs van verzending.
          </li>
          <li>
            Per brief die je afgeeft tegen ontvangstbewijs bij één van de
            betrokken gemeenten. Dat zijn de gemeenten waar het plan gelegen
            is.
          </li>
        </ol>
        </p>
        <vl-typography>
    `;
  }
}

define('grups-hoe-inspreken', GrupsHoeInspreken);
