import {getResource, resolve} from './rest';

const SINGLETON = {};
export {
  SINGLETON as config,
};

export const resolveConfig = async () => {
  if (SINGLETON.isLoaded) {
    return;
  }
  const data = await getResource({url: '/api'});
  const configUrl = resolve(data._links, 'config');
  Object.assign(SINGLETON, await getResource({url: configUrl}));
  SINGLETON.isLoaded = true;
};
